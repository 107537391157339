import React from 'react';
import { string, shape, oneOfType } from 'prop-types';

import { tagSchema, machineSchema } from '../../../../../API/hooks/useFetchEntities/types';
import cellStyles from '../TableSection.module.scss';

import styles from './InternalIdCell.module.scss';

export const InternalIdCell = ({ value, row: { original } }) => {
  const { leaveSite, pwsDisconnected, lowBattery } = original;

  return (
    <>
      {value === 'Hidden' ? (
        <div className={cellStyles.cell}>
          <i className="fa-regular fa-eye-slash" /> Hidden
        </div>
      ) : (
        <div className={`d-flex flex-column text-left ${cellStyles.cell}`}>
          <span className={styles.internalId}>{value}</span>
          <div className={styles.tagContainer}>
            {pwsDisconnected && (
              <div className={`${styles.tag} ${styles.danger}`}>PWS Disconnected</div>
            )}
            {leaveSite && <div className={`${styles.tag} ${styles.info}`}>OFF Site</div>}
            {lowBattery && <div className={`${styles.tag} ${styles.warning}`}>Battery Low</div>}
          </div>
        </div>
      )}
    </>
  );
};

InternalIdCell.propTypes = {
  value: string,
  row: shape({
    original: oneOfType([tagSchema, machineSchema]),
  }),
};
