import React, { useState } from 'react';
import { func, arrayOf, string, shape } from 'prop-types';

import { DRAWING_MODES, contactType } from 'components/Worksites/types';
import { projectType, worksiteType } from '../../API/types';

import WorksitesTable from './components/WorksitesTable/WorksitesTable';
import WorksitesEditor from './components/WorksitesEditor';
import WorksitesMap from './components/WorksitesMap';
import ZonesNav from './components/ZonesNav';
import { useWorksites } from './useWorksites';
import styles from './worksites.module.scss';

const Worksites = ({
  updateWorksiteData,
  worksites,
  projects: initialAllProjects,
  availableTechnicalContacts,
  // fetchWorksitesErrorMsg,
}) => {
  const [worksiteToEdit, setWorksiteToEdit] = useState(null);
  const [drawingMode, setDrawingMode] = useState(DRAWING_MODES.NONE);
  const [editedGeometryValue, setEditedGeometryValue] = useState();
  const [editedGoogleShapeReference, setGoogleShapeReference] = useState();

  const { tableData, mapData, searchFilter, filterWorksites } = useWorksites({
    worksites,
  });

  const editHandler = (id) => {
    const worksiteObject = worksites.find((site) => site.id === id);

    setWorksiteToEdit({ ...worksiteObject, hidden: false });
    setEditedGeometryValue(worksiteObject.geometry);
  };

  const clearEditedGeometry = () => {
    setEditedGeometryValue(null);
    // eslint-disable-next-line no-unused-expressions
    editedGoogleShapeReference && editedGoogleShapeReference.setMap(null);
    setGoogleShapeReference(null);
  };

  const cancelEditHandler = () => {
    setWorksiteToEdit(null);
    clearEditedGeometry();
  };

  const addWorksiteHandler = () => {
    const DEFAULT_SITE_COLOR = '#000000';

    setWorksiteToEdit({
      name: null,
      color: DEFAULT_SITE_COLOR,
    });
    setDrawingMode(DRAWING_MODES.POLYGON);
  };

  const onGeometryChange = (geometry) => {
    setEditedGeometryValue(geometry);
  };

  const onDrawingComplete = ({ geometry, shapeInstance }) => {
    setDrawingMode(DRAWING_MODES.NONE);
    setEditedGeometryValue(geometry);
    setGoogleShapeReference(shapeInstance);
  };

  const handleWorksiteSave = (worksiteFields) => {
    const newWorksiteObj = {
      ...worksiteToEdit,
      ...worksiteFields,
      geometry: editedGeometryValue || worksiteToEdit.geometry,
    };
    updateWorksiteData(newWorksiteObj);
    cancelEditHandler();
  };

  const handleWorksiteDelete = () => {
    updateWorksiteData(worksiteToEdit, true);
    cancelEditHandler();
  };

  const onDrawingModeChange = (newMode) => {
    const CONFIRMATION_MESSAGE = 'The current geometry will be cleared. Are you sure?';

    // eslint-disable-next-line no-restricted-globals
    if (editedGeometryValue && !confirm(CONFIRMATION_MESSAGE)) {
      return;
    }

    if (editedGeometryValue) {
      setWorksiteToEdit({ ...worksiteToEdit, hidden: true });
    }

    clearEditedGeometry();
    setDrawingMode(newMode);
  };

  const worksiteGeometryAvailableForSave =
    !!editedGeometryValue ||
    !!(worksiteToEdit && worksiteToEdit.geometry && !worksiteToEdit.hidden);

  return (
    <div className={`${styles.dashboardContainer}`}>
      <div className={`${styles.dashboardRow} ${styles.dashboardColumns}`}>
        <div className={`${styles.dashboardCol} ${styles.tableCol}`}>
          {worksiteToEdit ? (
            <WorksitesEditor
              {...worksiteToEdit}
              onDelete={handleWorksiteDelete}
              onSave={handleWorksiteSave}
              drawingMode={drawingMode}
              onDrawingModeChange={onDrawingModeChange}
              hasGeometry={worksiteGeometryAvailableForSave}
              initialAllProjects={initialAllProjects}
              availableTechnicalContacts={availableTechnicalContacts}
            />
          ) : (
            <WorksitesTable {...{ data: tableData, onEdit: editHandler }} />
          )}
        </div>
        <div className={`${styles.dashboardCol} ${styles.mapCol}`}>
          <ZonesNav
            {...{
              searchFilter,
              filterFn: filterWorksites,
              onAddWorksite: addWorksiteHandler,
              onClose: cancelEditHandler,
              isEditing: !!worksiteToEdit,
            }}
          />
          <div className={`${styles.map}`}>
            <WorksitesMap
              {...{
                data: mapData.map((site) => {
                  return {
                    ...site,
                    isEdited: worksiteToEdit && site.id === worksiteToEdit.id,
                    hidden:
                      worksiteToEdit && site.id === worksiteToEdit.id && worksiteToEdit.hidden,
                  };
                }),
                drawingMode,
                onGeometryChange,
                onDrawingComplete,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Worksites.propTypes = {
  updateWorksiteData: func,
  worksites: arrayOf(worksiteType),
  projects: arrayOf(projectType),
  availableTechnicalContacts: arrayOf(contactType),
  fetchWorksitesErrorMsg: shape({
    msg: string,
    className: string,
  }),
};

export default (props) => <Worksites {...props} />;
