import React from 'react';
import { shape, string } from 'prop-types';

import styles from './phone.module.css';
import { PhoneForm } from './phoneForm/phoneForm';

const Phone = ({ initialData }) => {
  const defaultPhoneNumber = initialData.phone
    ? `+${initialData.phoneCountryCode}${initialData.phone}`
    : '';

  return (
    <div className={styles.container}>
      <PhoneForm defaultPhoneNumber={defaultPhoneNumber} userId={initialData.userId} />
    </div>
  );
};

Phone.propTypes = {
  initialData: shape({
    phone_country_code: string.isRequired,
    phone: string.isRequired,
    userId: string.isRequired,
  }),
};

export default Phone;
