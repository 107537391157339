import React from 'react';
import DefaultPhoneInput from 'react-phone-number-input/input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useFormContext } from 'react-hook-form';

import styles from './phoneInput.module.css';

export const PhoneInput = () => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const phoneNumber = watch('phone');

  const handleChange = (value) => {
    setValue('phone', value || '', {
      shouldValidate: true,
    });
  };

  const validatePhone = (value) => {
    return value ? isValidPhoneNumber(value) || 'Please enter a valid phone number' : true;
  };

  return (
    <div>
      <div className={`${styles.inputContainer} ${errors.phone ? styles.invalid : ''}`}>
        <div className={styles.iconWrapper}>
          <i className="fa-light fa-mobile-screen" />
        </div>
        <div className={styles.inputWrapper}>
          <label htmlFor="phone" className={styles.inputLabel}>
            Mobile phone number
          </label>
          <DefaultPhoneInput
            defaultCountry="US"
            value={phoneNumber}
            onChange={handleChange}
            className={styles.phoneInput}
            placeholder="+1 000-000-0000"
            {...register('phone', { validate: validatePhone })}
          />
        </div>
      </div>
      {errors.phone && <p className={styles.errorMessage}>{errors.phone.message}</p>}
    </div>
  );
};
