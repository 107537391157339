import React, { useState, useRef, useEffect } from 'react';
import PropTypes, { number } from 'prop-types';

import { DRAWING_MODES, drawingModeType, contactType } from '../types';
import { TextField, MultiSelect } from '../../../atoms';
import { projectType, siteNameType } from '../../../API/types';
import {
  changeCurrentItemsFormat,
  changeSelectedItemsFormat,
} from '../../../atoms/MultiSelect/utils';
import ColorPicker from '../../ColorPicker';
import useCalculateHeight from '../../../hooks/useCalculateHeight';
// eslint-disable-next-line import/namespace
import { capitalizeAll } from '../../../utils';
import styles from '../worksites.module.scss';

import { useWorksiteEditor } from './useWorksiteEditor';

const siteOptions = [
  { value: 'infrastructure', label: 'Infrastructure' },
  { value: 'residential construction', label: 'Residential Construction' },
  { value: 'commercial construction', label: 'Commercial Construction' },
  { value: 'rail maintenance', label: 'Rail Maintenance' },
  { value: 'waste & recycling', label: 'Waste & Recycling' },
  { value: 'agriculture', label: 'Agriculture' },
  { value: 'forestry', label: 'Forestry' },
  { value: 'logistics', label: 'Logistics' },
  { value: 'other', label: 'Other' },
];

const formatTechnicalContacts = (technicalContacts, availableTechnicalContacts) => {
  if (!technicalContacts || !availableTechnicalContacts) return [];

  return technicalContacts.map((contactId) => {
    const foundContact = availableTechnicalContacts.find(
      (availableContact) => availableContact.id === contactId,
    );

    return {
      value: foundContact.id,
      label: foundContact.name,
    };
  });
};

const WorksitesEditor = ({
  name,
  color,
  projects: initialWorksiteProjects,
  siteType,
  onDelete,
  hasGeometry,
  drawingMode,
  onDrawingModeChange,
  onSave,
  initialAllProjects,
  technicalContacts,
  availableTechnicalContacts,
}) => {
  const initialSiteType = siteType
    ? { value: siteType, label: capitalizeAll(siteType) }
    : siteOptions[0];

  const [editedName, setEditedName] = useState(name);
  const [editedColor, setEditedColor] = useState(color);
  const [validationErrors, setValidationErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [currentProjects, setCurrentProjects] = useState(initialWorksiteProjects);
  const [currentSiteType, setCurrentSiteType] = useState(initialSiteType);
  const [currentTechnicalContact, setCurrentTechnicalContact] = useState(
    formatTechnicalContacts(technicalContacts, availableTechnicalContacts),
  );

  const {
    projectOptions,
    isLoading,
    deleteProjectOption,
    addProjectOption,
    fetchProjectsErrorMsg,
    mutationError,
  } = useWorksiteEditor(initialAllProjects);

  const editorContainerRef = useRef();
  const editorHeight = useCalculateHeight(editorContainerRef, -10);
  const preset = {
    primaryColor: editedColor,
  };

  const validator = () => {
    const errors = {};

    if (!editedName || editedName.length > 100) {
      errors.name = "You must provide worksite's name (max 100 chars)";
    }

    if (!hasGeometry) {
      errors.geometry = "You must provide worksite's geometry";
    }

    return setValidationErrors(errors);
  };

  const saveHandler = () => {
    validator();
    setIsSaving(true);
  };

  useEffect(() => {
    if (isSaving) {
      setIsSaving(false);
      if (Object.keys(validationErrors).length === 0) {
        onSave({
          name: editedName,
          color: editedColor,
          projects: currentProjects,
          siteType: currentSiteType.value,
          technicalContacts: currentTechnicalContact.map((contact) => contact.value),
        });
      }
    }
  }, [isSaving, validationErrors, editedName, editedColor, currentProjects, currentSiteType.value]);

  const deleteHandler = (event) => {
    event.preventDefault();
    onDelete();
  };

  return (
    <div>
      <span className={styles.subtitle}>{name ? 'Edit Site' : 'Create Site'}</span>
      <div
        ref={editorContainerRef}
        className="bg-white rounded border p-3"
        style={{ minHeight: editorHeight }}
      >
        <div className="form-group">
          <label htmlFor="worksite-name">Name</label>
          <TextField
            placeholder="Worksite name"
            onChange={(event) => {
              setEditedName(event.target.value.trim());
            }}
            defaultValue={name}
            errors={validationErrors.name ? [validationErrors.name] : undefined}
            id="worksite-name"
          />
        </div>
        <div className="form-group">
          <div className="mb-2">Colour</div>
          <div className="w-100">
            <ColorPicker
              {...{ preset }}
              dispatchColor={(colorToEdit) => setEditedColor(colorToEdit)}
              fullWidth
            />
          </div>
        </div>
        <div className="form-group">
          <div className="mb-2">Mapping</div>
          <div className="d-flex">
            <button
              type="button"
              className={`btn btn-outline-secondary flex-grow-1 mr-1 ${
                drawingMode === DRAWING_MODES.POLYGON ? 'active' : ''
              }`}
              onClick={() => {
                onDrawingModeChange(DRAWING_MODES.POLYGON);
              }}
            >
              <i className="fal fa-draw-polygon" /> Draw
            </button>
            <button
              type="button"
              className={`btn btn-outline-secondary flex-grow-1 ml-1 ${
                drawingMode === DRAWING_MODES.CIRCLE ? 'active' : ''
              }`}
              onClick={() => {
                onDrawingModeChange(DRAWING_MODES.CIRCLE);
              }}
            >
              <i className="fal fa-draw-circle" /> Circle
            </button>
          </div>
          {validationErrors.geometry && (
            <small id="drawingModeError" className="form-text text-danger">
              {validationErrors.geometry}
            </small>
          )}
          {drawingMode !== DRAWING_MODES.NONE && (
            <small id="drawingModeHelp" className="form-text text-muted">
              Click on the map to start drawing
            </small>
          )}
        </div>
        <div className="d-flex flex-column mb-3">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span>Projects</span>
            {(fetchProjectsErrorMsg.msg || mutationError) && (
              <span className={`text-danger ${styles.editFetchError}`}>
                {mutationError || fetchProjectsErrorMsg.msg}
              </span>
            )}
          </div>
          <MultiSelect
            currentValues={changeCurrentItemsFormat(currentProjects)}
            initialValues={changeCurrentItemsFormat(currentProjects)}
            options={projectOptions}
            onChange={(selectedValues) =>
              setCurrentProjects(changeSelectedItemsFormat(selectedValues))
            }
            isCreatable
            isLoading={isLoading}
            onCreateOption={addProjectOption}
            deleteOption={deleteProjectOption}
          />
        </div>
        <div className="d-flex flex-column mb-3">
          <span className="mb-2">Site Type</span>
          <MultiSelect
            currentValues={currentSiteType}
            initialValues={currentSiteType}
            options={siteOptions}
            onChange={(selectedValues) => setCurrentSiteType(selectedValues)}
            configOptions={{
              isMulti: false,
              closeMenuOnSelect: true,
              isSearchable: true,
            }}
          />
        </div>
        {availableTechnicalContacts && (
          <div className="d-flex flex-column mb-3">
            <span className="mb-2">Technical Contact</span>
            <MultiSelect
              currentValues={currentTechnicalContact}
              initialValues={currentTechnicalContact}
              options={availableTechnicalContacts.map((contact) => ({
                value: contact.id,
                label: contact.name,
              }))}
              onChange={(selectedValues) =>
                selectedValues
                  ? setCurrentTechnicalContact(selectedValues)
                  : setCurrentTechnicalContact([])
              }
            />
          </div>
        )}
        <button
          type="button"
          className={`text-secondary d-block my-4 ${styles.deleteSiteButton}`}
          onClick={deleteHandler}
        >
          <i className="far fa-trash-alt" /> Delete Site
        </button>
        <button
          type="button"
          className={`btn btn-success ${styles.activateButton} d-block w-100`}
          onClick={saveHandler}
        >
          Save Site
        </button>
      </div>
    </div>
  );
};

WorksitesEditor.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  projects: PropTypes.arrayOf(projectType),
  siteType: siteNameType,
  drawingMode: drawingModeType,
  hasGeometry: PropTypes.bool,
  onDelete: PropTypes.func,
  onDrawingModeChange: PropTypes.func,
  onSave: PropTypes.func,
  initialAllProjects: PropTypes.arrayOf(projectType),
  technicalContacts: PropTypes.arrayOf(number),
  availableTechnicalContacts: PropTypes.arrayOf(contactType),
};

export default WorksitesEditor;
