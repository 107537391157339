import { oneOf, shape, string, number } from 'prop-types';

export const DRAWING_MODES_ARRAY = ['NONE', 'POLYGON', 'CIRCLE'];
export const DRAWING_MODES = DRAWING_MODES_ARRAY.reduce(
  (accumulator, current) => ({ ...accumulator, [current]: current }),
  {},
);
export const drawingModeType = oneOf(DRAWING_MODES_ARRAY);

export const contactType = shape({
  id: number.isRequired,
  name: string.isRequired,
});
