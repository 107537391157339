import { string, arrayOf, oneOf, number, shape } from 'prop-types';

export const alertType = shape({
  id: number.isRequired,
  siteIds: arrayOf(string).isRequired,
  kind: oneOf(['pws_disconnected', 'low_battery', 'leave_site']).isRequired,
  availableSites: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    }),
  ).isRequired,
});
