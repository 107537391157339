import { shape, arrayOf, oneOf, string, number, oneOfType } from 'prop-types';

import { fetchedPeriodType, projectType, worksiteType } from '../../types';

export const statusType = ['waiting', 'processing', 'ready', 'error'];

export const reportType = shape({
  id: number,
  period: oneOfType([fetchedPeriodType, string]),
  sites: arrayOf(worksiteType),
  projects: arrayOf(projectType),
  status: oneOf(statusType),
  createdAt: string,
  filename: string,
  downloadUrl: string,
});

export const reportsType = arrayOf(reportType);
