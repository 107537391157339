import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('.multi-checkboxes__input').forEach((checkbox) => {
      checkbox.addEventListener('change', () => this.handleCheckboxChange(checkbox));
      this.updateStyle(checkbox);
    });

    const selectAllCheckbox = document.getElementById('select_all');
    if (selectAllCheckbox) {
      selectAllCheckbox.addEventListener('change', () => this.toggleSelectAll());
    }
    this.updateSelectAllState();
  }

  handleCheckboxChange(checkbox) {
    if (checkbox.id !== 'select_all') {
      const selectAllCheckbox = document.getElementById('select_all');
      if (selectAllCheckbox.checked) {
        selectAllCheckbox.checked = false;
        this.updateStyle(selectAllCheckbox);
        this.element.querySelectorAll('.multi-checkboxes__input').forEach((cb) => {
          if (cb.id !== checkbox.id && cb.id !== 'select_all') {
            cb.checked = false;
            this.updateStyle(cb);
          } else if (cb.id === checkbox.id) {
            cb.checked = true;
            this.updateStyle(cb);
          }
        });
      }
    }
    this.updateStyle(checkbox);
  }

  updateStyle(checkbox) {
    const label = this.element.querySelector(`label[for='${checkbox.id}']`);
    if (checkbox.checked) {
      label.classList.add('selected');
    } else {
      label.classList.remove('selected');
    }
  }

  toggleSelectAll() {
    const selectAllCheckbox = document.getElementById('select_all');
    const isChecked = selectAllCheckbox.checked;
    const otherCheckboxes = Array.from(
      this.element.querySelectorAll('.multi-checkboxes__input'),
    ).filter((cb) => cb.id !== 'select_all');

    if (isChecked) {
      otherCheckboxes.forEach((checkbox) => {
        checkbox.checked = true;
        this.updateStyle(checkbox);
      });
    } else {
      otherCheckboxes.forEach((checkbox) => {
        checkbox.checked = false;
        this.updateStyle(checkbox);
      });
    }
    this.updateStyle(selectAllCheckbox);
    this.updateSelectAllState();
  }

  updateSelectAllState() {
    const otherCheckboxes = Array.from(
      this.element.querySelectorAll('.multi-checkboxes__input'),
    ).filter((cb) => cb.id !== 'select_all');

    const allChecked = otherCheckboxes.every((cb) => cb.checked);

    const selectAllCheckbox = document.getElementById('select_all');
    const selectAllLabel = document.querySelector("label[for='select_all']");

    if (allChecked && otherCheckboxes.length > 0) {
      selectAllCheckbox.checked = true;
      selectAllLabel.classList.add('selected');
      otherCheckboxes.forEach((cb) => {
        const label = document.querySelector(`label[for='${cb.id}']`);
        label.classList.remove('selected');
      });
    } else {
      selectAllCheckbox.checked = false;
      selectAllLabel.classList.remove('selected');
    }
  }
}
