import styles from './Toast.module.scss';

const showToast = (message, type = 'success') => {
  const toast = document.createElement('div');
  toast.className = `${styles.toast} ${styles[type]}`;
  toast.textContent = message;

  document.body.appendChild(toast);

  setTimeout(() => {
    toast.classList.add(styles.toastHide);
    setTimeout(() => {
      document.body.removeChild(toast);
    }, 300);
  }, 5000);
};

export default showToast;
