import React from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

import colors from '../../../assets/stylesheets/_colors.scss';

import { optionType, menuListType, multiSelectType } from './types';
import styles from './MultiSelect.module.scss';

const animatedComponents = makeAnimated();

const Option = ({ selectProps, data, ...props }) => {
  const { deleteOption } = selectProps;

  return (
    <div className="d-flex">
      <components.Option {...props} />
      {!data.isNew && (
        <button
          type="button"
          className={`btn ${styles.removeBtn}`}
          onClick={() => deleteOption(data.value)}
        >
          <i className="fal fa-trash-alt" />
        </button>
      )}
    </div>
  );
};

Option.propTypes = optionType;

const MenuList = ({ selectProps, children, ...props }) => {
  const { isLoading } = selectProps;

  return (
    <components.MenuList {...props}>
      {isLoading ? (
        <div style={{ padding: '8px 12px', textAlign: 'center' }}>Loading...</div>
      ) : (
        children
      )}
    </components.MenuList>
  );
};

MenuList.propTypes = menuListType;

export const MultiSelect = ({
  options,
  onChange,
  currentValues,
  initialValues = [],
  placeholder = '',
  className = '',
  isCreatable = false,
  configOptions = {
    isMulti: true,
    closeMenuOnSelect: false,
    isSearchable: true,
  },
  isLoading = false,
  deleteOption,
  onCreateOption,
  label = '',
  wrapperClassName = '',
  isDark = false,
  isDisabled = false,
}) => {
  const customStyles = {
    control: (provided, { isFocused }) => {
      return {
        ...provided,
        boxShadow: isFocused && !isDark ? colors.blueBoxShadow : null,
        border: isDark ? '1px solid #fff' : provided.border,
        color: isDark ? '#fff' : provided.color,
        backgroundColor: isDark ? '#131922' : provided.backgroundColor,
        ':hover': {
          ...provided[':hover'],
          border: isDark ? '1px solid #fff' : undefined,
          cursor: isDark ? 'text' : undefined,
        },
      };
    },
    container: (provided) => ({
      ...provided,
      minWidth: 150,
    }),
    option: (provided, { isFocused }) => {
      return {
        ...provided,
        cursor: 'pointer',
        backgroundColor: isDark && isFocused ? '#80bdff' : provided.backgroundColor,
        ':hover': {
          ...provided[':hover'],
          backgroundColor: isDark ? '#80bdff' : undefined,
        },
      };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        color: isDark ? '#fff' : provided.color,
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        border: isDark ? '1px solid #fff' : provided.border,
        color: isDark ? '#fff' : provided.color,
        backgroundColor: isDark ? '#131922' : provided.backgroundColor,
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        color: isDark ? '#fff' : provided.color,
      };
    },
  };

  const customTheme = (theme) => {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: colors.blueFocusBorder,
        neutral20: colors.grayBorder,
        neutral50: colors.graySecondary,
      },
    };
  };

  return (
    <div className={`d-flex flex-column ${wrapperClassName}`}>
      {label && <span className={styles.label}>{label}</span>}
      {isCreatable ? (
        <CreatableSelect
          value={currentValues}
          defaultValue={initialValues}
          options={options}
          isMulti={configOptions.isMulti}
          styles={customStyles}
          theme={customTheme}
          components={(animatedComponents, { Option, MenuList })}
          isSearchable={configOptions.isSearchable}
          placeholder={placeholder}
          onChange={onChange}
          className={className}
          closeMenuOnSelect={configOptions.closeMenuOnSelect}
          isLoading={isLoading}
          isDisabled={isLoading}
          onCreateOption={onCreateOption}
          // own props
          deleteOption={deleteOption}
        />
      ) : (
        <Select
          value={currentValues}
          defaultValue={initialValues}
          options={options}
          isMulti={configOptions.isMulti}
          styles={customStyles}
          theme={customTheme}
          components={animatedComponents}
          isSearchable={configOptions.isSearchable}
          placeholder={placeholder}
          onChange={onChange}
          className={className}
          closeMenuOnSelect={configOptions.closeMenuOnSelect}
          hideSelectedOptions
          isDisabled={isDisabled}
        />
      )}
    </div>
  );
};

MultiSelect.propTypes = multiSelectType;
