import React from 'react';
import { element } from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { MultiSelect } from 'atoms';
import { alertType } from '../Alerts.types';
import { SwitcherInput } from 'atoms/Switcher/components/index';

import styles from './alertSetting.module.scss';

export const AlertSetting = ({ alert, titleElement }) => {
  const { register, setValue, watch } = useFormContext();
  const selectedSites = watch(alert.kind) || [];
  const isAlertEnabled = watch(`switcher-${alert.kind}`) || false;

  const formattedSites = selectedSites.map((siteId) => ({
    value: Number(siteId),
    label: alert.availableSites.find((site) => site.id.toString() === siteId.toString()).name,
  }));

  return (
    <div className={styles.alertSetting}>
      <h3 className={styles.title}>{titleElement}</h3>
      <div className={styles.switcherContainer}>
        Enable for all sites
        <SwitcherInput
          ref={register}
          onChange={() => setValue(`switcher-${alert.kind}`, !isAlertEnabled)}
          checked={isAlertEnabled}
          switcherId={alert.kind}
          variant="green"
        />
      </div>
      <div className={styles.multiSelectContainer}>
        Enable for specific sites{' '}
        <MultiSelect
          initialValues={formattedSites}
          currentValues={formattedSites}
          options={[
            ...alert.availableSites.map((site) => ({
              value: site.id,
              label: site.name,
            })),
          ]}
          onChange={(changedSites) => {
            setValue(alert.kind, changedSites ? changedSites.map((site) => site.value) : []);
          }}
          isDisabled={isAlertEnabled}
          {...register(alert.kind)}
        />
      </div>
    </div>
  );
};

AlertSetting.propTypes = {
  alert: alertType,
  titleElement: element,
};
