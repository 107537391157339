import React from 'react';
import { func, bool, string } from 'prop-types';

import { SwitcherWrapper, SwitcherInput, SwitcherLabelWithTooltip } from '../components';

export const SwitcherWithTooltip = ({
  onChange,
  checked,
  labelText,
  switcherId,
  tooltipText,
  wrapperClassName = '',
  variant = 'default',
}) => (
  <SwitcherWrapper wrapperClassName={wrapperClassName}>
    <SwitcherLabelWithTooltip
      labelText={labelText}
      switcherId={switcherId}
      tooltipText={tooltipText}
    />
    <SwitcherInput
      onChange={onChange}
      checked={checked}
      switcherId={switcherId}
      labelText={labelText}
      variant={variant}
    />
  </SwitcherWrapper>
);

SwitcherWithTooltip.propTypes = {
  onChange: func.isRequired,
  checked: bool.isRequired,
  labelText: string.isRequired,
  switcherId: string.isRequired,
  tooltipText: string,
  wrapperClassName: string,
  variant: string,
};
