import React from 'react';
import { func, bool, string } from 'prop-types';

import styles from './Switcher.module.scss';

export const SwitcherInput = React.forwardRef(
  ({ onChange, checked, switcherId, variant = 'default' }, ref) => {
    const handleChange = (e) => onChange(e.target.checked);

    return (
      <>
        <input
          ref={ref}
          type="checkbox"
          id={`switcher-${switcherId}`}
          name={`switcher-${switcherId}`}
          onChange={handleChange}
          checked={checked}
          className={styles.switcherLogic}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          htmlFor={`switcher-${switcherId}`}
          className={`${styles.switcher} ${styles[variant]}`}
        />
      </>
    );
  },
);

SwitcherInput.propTypes = {
  onChange: func.isRequired,
  checked: bool.isRequired,
  switcherId: string.isRequired,
  variant: string,
};
