import React from 'react';
import { string } from 'prop-types';
import ReactTooltip from 'react-tooltip';

import styles from './SwitcherLabel.module.scss';

export const SwitcherLabelWithTooltip = ({ labelText, switcherId, tooltipText }) => {
  return (
    <>
      <span data-tip data-for={`tooltip-switcher-${switcherId}`}>
        {labelText}
      </span>
      <ReactTooltip id={`tooltip-switcher-${switcherId}`} className={styles.tooltip}>
        {tooltipText}
      </ReactTooltip>
    </>
  );
};

SwitcherLabelWithTooltip.propTypes = {
  labelText: string.isRequired,
  switcherId: string.isRequired,
  tooltipText: string,
};
