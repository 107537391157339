import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { node, arrayOf } from 'prop-types';
import axios from 'axios';

import showToast from '../../atoms/Toast/Toast';
import { alertType } from '../Alerts.types';

import styles from './alertsForm.module.scss';

const formatData = (data, alerts) =>
  alerts.map((alert) => {
    const isSwitcherEnabled = data[`switcher-${alert.kind}`];
    const selectedSites = data[alert.kind];

    switch (true) {
      case isSwitcherEnabled:
        return {
          id: alert.id,
          siteIds: alert.availableSites.map((site) => site.id),
        };
      case selectedSites?.length > 0:
        return {
          id: alert.id,
          siteIds: selectedSites,
        };
      default:
        return {
          id: alert.id,
          siteIds: [],
        };
    }
  });

const checkIfAllSitesAreSelected = (alert) => alert.availableSites.length === alert.siteIds.length;

export const AlertsForm = ({ children, alerts }) => {
  const methods = useForm({
    defaultValues: {
      ...alerts.reduce((acc, alert) => {
        const isAlertEnabled = checkIfAllSitesAreSelected(alert);
        acc[`switcher-${alert.kind}`] = isAlertEnabled;

        if (!isAlertEnabled) {
          acc[alert.kind] = alert.siteIds;
        }

        return acc;
      }, {}),
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (data) => {
    setIsLoading(true);
    const formattedAlertsData = formatData(data, alerts);

    try {
      await Promise.all(
        formattedAlertsData
          .filter((alertData) => alertData.siteIds !== undefined)
          .map((alertData) =>
            axios.patch(`${process.env.API_URL}/alerts/${alertData.id}`, {
              site_ids: alertData.siteIds,
            }),
          ),
      );

      alerts.forEach((alert) => {
        if (data[`switcher-${alert.kind}`]) {
          methods.setValue(alert.kind, []);
        }

        if (data[alert.kind]?.length === alert.availableSites.length) {
          methods.setValue(alert.kind, []);
          methods.setValue(`switcher-${alert.kind}`, true);
        }
      });

      showToast('Alerts saved successfully');
    } catch (error) {
      showToast('Something went wrong, please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(handleSubmit)}
        method="POST"
        noValidate
        className="card p-5 d-flex flex-column gap-10"
      >
        <p>
          For all alerts an email and SMS will be sent to the Technical Contact listed for each
          site. This contact can be added under the sites page.
        </p>
        {children}
        <button type="submit" className={styles.alertsSaveButton}>
          {isLoading ? 'Saving...' : 'Save'}
        </button>
      </form>
    </FormProvider>
  );
};

AlertsForm.propTypes = {
  children: node.isRequired,
  alerts: arrayOf(alertType).isRequired,
};
