import { string, arrayOf, shape, oneOfType, number, bool, func, node } from 'prop-types';

const multiDataType = arrayOf(
  shape({
    value: oneOfType([string, number]),
    label: string,
  }),
);

const singleDataType = shape({
  value: oneOfType([string, number]),
  label: string,
});

export const dataType = oneOfType([multiDataType, singleDataType]);

export const configOptionsType = shape({
  isMulti: bool,
  closeMenuOnSelect: bool,
  isSearchable: bool,
});

export const optionType = {
  selectProps: shape({
    deleteOption: func,
  }).isRequired,
  data: dataType.isRequired,
};

export const menuListType = {
  selectProps: shape({
    isLoading: bool,
  }).isRequired,
  children: node,
};

export const multiSelectType = {
  options: dataType.isRequired,
  onChange: func.isRequired,
  currentValues: dataType,
  initialValues: dataType,
  placeholder: string,
  className: string,
  isCreatable: bool,
  configOptions: configOptionsType,
  isLoading: bool,
  deleteOption: func,
  onCreateOption: func,
  label: string,
  wrapperClassName: string,
  isDark: bool,
  isDisabled: bool,
};
