import { useForm, FormProvider } from 'react-hook-form';
import React from 'react';
import { parsePhoneNumber } from 'react-phone-number-input';
import axios from 'axios';
import { string } from 'prop-types';

import showToast from '../../../atoms/Toast/Toast';

import styles from './phoneForm.module.css';
import { PhoneInput } from './phoneInput/phoneInput';

export const PhoneForm = ({ defaultPhoneNumber, userId }) => {
  const methods = useForm({
    defaultValues: {
      phone: defaultPhoneNumber.replace('+', ''),
    },
  });

  const handleSubmit = async (data) => {
    const parsedPhoneNumber = data.phone ? parsePhoneNumber(data.phone) : null;
    const body = parsedPhoneNumber
      ? {
          phone_country_code: `+${parsedPhoneNumber.countryCallingCode}`,
          phone: parsedPhoneNumber.nationalNumber,
        }
      : null;

    try {
      await axios.patch(`../users/${userId}`, body);

      showToast('Phone number updated successfully');
    } catch (error) {
      showToast('Something went wrong, please try again.', 'error');
    }
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.formCard}>
        <form
          onSubmit={methods.handleSubmit(handleSubmit)}
          method="PATCH"
          noValidate
          className={styles.formCardContent}
        >
          <h1 className={styles.heading}>SMS Alerts</h1>
          <PhoneInput />
          <div>
            <p className={styles.text}>
              By entering my mobile number, I agree to receive alert texts from SensorZone. Message
              frequency varies. Message + data rates may apply. Reply STOP to unsubscribe or HELP
              for help.
            </p>
            <a
              href="https://www.sensorzone.io/sms-terms"
              target="_blank"
              className={styles.link}
              rel="noreferrer"
            >
              View terms/conditions and privacy policy here.
            </a>
          </div>
          <button type="submit" className={styles.button} disabled={methods.formState.isSubmitting}>
            {methods.formState.isSubmitting
              ? 'Submitting...'
              : 'Accept and enable SMS notifications'}
          </button>
        </form>
      </div>
    </FormProvider>
  );
};

PhoneForm.propTypes = {
  defaultPhoneNumber: string,
  userId: string,
};
