import React from 'react';
import { arrayOf, shape } from 'prop-types';

import { alertType } from './Alerts.types';
import { AlertSetting } from './alertSetting/alertSetting';
import { AlertsForm } from './alertsForm/alertsForm';

const Alerts = ({ initialData }) => (
  <AlertsForm alerts={initialData.alerts}>
    <AlertSetting
      alert={initialData.alerts.find((alert) => alert.kind === 'leave_site')}
      titleElement={
        <span>
          Alert when a machine <strong>leaves a site</strong>
        </span>
      }
    />
    <AlertSetting
      alert={initialData.alerts.find((alert) => alert.kind === 'pws_disconnected')}
      titleElement={
        <span>
          Alert when a machine is moving without a <strong>PWS enabled</strong>
        </span>
      }
    />
    <AlertSetting
      alert={initialData.alerts.find((alert) => alert.kind === 'low_battery')}
      titleElement={
        <span>
          Alert when a machine will shortly stop sending alerts due to{' '}
          <strong>low backup battery</strong>
        </span>
      }
    />
  </AlertsForm>
);

Alerts.propTypes = {
  initialData: shape({
    alerts: arrayOf(alertType),
  }),
};

export default Alerts;
