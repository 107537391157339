import React from 'react';
import { SWRConfig } from 'swr';

// eslint-disable-next-line import/namespace
import { fetchApi } from 'utils';

import Alerts from './Alerts';

export const APILinkedAlerts = (props) => (
  <SWRConfig value={{ fetcher: fetchApi }}>
    <Alerts {...props} />
  </SWRConfig>
);

export default APILinkedAlerts;
